<div class="max-w-5xl p-4 mx-auto mt-8">
	<div class="flex flex-col items-center justify-between md:flex-row">
		<!-- Left Section: Open Access Logo -->
		<div class="mb-4 mr-6 md:mb-0">
			<img src="/images/logos/open-access_v001.svg" alt="Open Access" class="w-20 mr-6 md:w-56" />
		</div>

		<!-- Right Section: CC Licenses and Descriptions -->
		<div class="text-left">
			<!-- CC-0 License -->
			<div class="flex items-center mb-2">
				<img src="/images/logos/licenses/cc-zero.svg" alt="CC-0 Logo" class="w-12 mr-6 md:w-16" />
				<span class="text-xs text-gray-700">
					DCPapers publishes its metadata under the Creative Commons Zero Universal Public Domain
					Dedication (CC0), allowing unrestricted reuse. Anyone can freely use the metadata from
					DCPapers articles for any purpose without limitations.
				</span>
			</div>

			<!-- CC-BY License -->
			<div class="flex items-center">
				<img src="/images/logos/licenses/cc-by.svg" alt="CC-BY Logo" class="w-12 mr-6 md:w-16" />
				<span class="text-xs text-gray-700">
					The article full-text is licensed under the Creative Commons Attribution 4.0 International
					License (CC BY 4.0). This license allows use, sharing, adaptation, distribution, and
					reproduction in any medium or format, provided that appropriate credit is given to the
					original author(s) and the source is cited.
				</span>
			</div>
		</div>
	</div>
</div>
