<div class="py-4 mx-20 bg-white sm:py-6">
	<div class="max-w-5xl px-6 mx-auto lg:px-8">
		<div
			class="grid items-center max-w-lg grid-cols-4 mx-auto gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5"
		>
			<!-- Wikidata Logo -->
			<img
				class="object-contain w-full col-span-2 max-h-12 lg:col-span-1"
				src="/images/logos/landing/wikidata-logo-en_v001.svg"
				alt="Wikidata"
				width="158"
				height="48"
			/>
			<!-- DBLP Logo -->
			<img
				class="object-contain w-full col-span-2 max-h-12 lg:col-span-1"
				src="/images/logos/landing/dblp_logo_v001.png"
				alt="DBLP"
				width="158"
				height="48"
			/>
			<!-- ORCID Logo -->
			<img
				class="object-contain w-full col-span-2 max-h-12 lg:col-span-1"
				src="/images/logos/landing/orcid_logo_v001.svg"
				alt="ORCID"
				width="158"
				height="48"
			/>
			<!-- DOI Logo -->
			<img
				class="object-contain w-full col-span-2 max-h-12 sm:col-start-2 lg:col-span-1"
				src="/images/logos/landing/doi_logo_v001.svg"
				alt="DOI"
				width="158"
				height="48"
			/>
			<!-- ACM DL Logo -->
			<img
				class="object-contain w-full col-span-2 col-start-2 max-h-12 sm:col-start-auto lg:col-span-1"
				src="/images/logos/landing/acm-dl_logo_v001.svg"
				alt="ACM Digital Library"
				width="158"
				height="48"
			/>
			<!-- Semantic Scholar Logo -->
			<img
				class="object-contain w-full col-span-2 max-h-12 lg:col-span-1"
				src="/images/logos/landing/semantic_scholar_logo_v001.svg"
				alt="Semantic Scholar"
				width="158"
				height="48"
			/>
			<!-- OpenAlex Logo -->
			<img
				class="object-contain w-full col-span-2 max-h-12 lg:col-span-1"
				src="/images/logos/landing/openalex_logo_v001.png"
				alt="OpenAlex"
				width="158"
				height="48"
			/>
			<!-- Scholar Logo -->
			<img
				class="object-contain w-full col-span-2 max-h-12 lg:col-span-1"
				src="/images/logos/landing/scholar_logo_v001.png"
				alt="Google Scholar"
				width="158"
				height="48"
			/>
			<!-- Scopus Logo -->
			<img
				class="object-contain w-full col-span-2 max-h-12 sm:col-start-2 lg:col-span-1"
				src="/images/logos/landing/scopus_logo_v001.svg"
				alt="Scopus"
				width="158"
				height="48"
			/>
			<!-- WorldCat Logo -->
			<img
				class="object-contain w-full col-span-2 col-start-2 max-h-12 sm:col-start-auto lg:col-span-1"
				src="/images/logos/landing/worldcat_logo_v001.svg"
				alt="WorldCat"
				width="158"
				height="48"
			/>
		</div>
	</div>
</div>
