<div class="py-16 sm:py-24 lg:pt-32">
	<div class="px-6 mx-auto max-w-7xl lg:px-8">
		<div class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
			<h2 class="text-center">Dublin Core Papers</h2>
			<p class="max-w-xl mx-auto mt-2 text-lg font-normal leading-8 text-center text-gray-600">
				DCPapers, with over 20 years of publishing history, is a diamond open access portal for the
				Dublin Core Conference Proceedings.
			</p>
		</div>
		<form class="max-w-md mx-auto mt-10" action="/search/" method="get">
			<div class="flex gap-x-4">
				<label for="search" class="sr-only">Search</label>
				<input
					id="search"
					name="q"
					type="search"
					autocomplete=""
					required
					class="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
					placeholder="Enter your search query"
				/>
				<button
					type="submit"
					class="flex-none rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
					>Search</button
				>
			</div>
			<!--
        <p class="mx-auto mt-4 text-sm leading-6 text-center text-gray-900">search in titles, authors, keywords, and abstracts <a href="#" class="font-semibold text-orange-600 hover:text-orange-500">privacy&nbsp;policy</a>.</p>
        -->
		</form>
	</div>
</div>
